.footer {
    display: block;
    /*background: #002336;*/
    background: #FFFFFF;
    justify-content: left;
    padding-top: 1%;
    padding-bottom: 0.01%;
    padding-left: 3%;
}

.footer-p1 {
    font-family: sans-serif;
    font-size: 16px;
    text-align: center;
}

.hr-line:after {
    content: " ";
    display: block;
    border-bottom: 1px solid #E5E5E5;
}

.footer-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    padding: 4% 8% 5% 12%;
}

.text-pl {
    margin-left: 22px;
    margin-top: 20px;
}

.text-pl2 {
    margin-left: 20px;
    margin-top: 20px;
}

.text-pl3 {
    margin-left: 20px;
    margin-top: 20px;
}

@media only screen and (min-width: 768px) {
    .bottom-hide {
        display: none;
    }

}

@media only screen and (max-width: 768px) {
    .footer-h1 {
        display: none;

    }

    .show-footer {
        display: none;
    }

    .show-h1 {
        display: none;
    }

    .text-pl {
        margin-left: 10px;
        margin-top: 20px;
    }

    .footer-grid {
        padding: 4% 0 5% 9%;
    }

}

