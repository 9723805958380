.services-grid1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: bottom;
    background-color: #FFFFFF;
    padding: 5% 8% 2% 8%;
}

.service-p1 {
    margin-right: 60px;
    font-size: 16px;
    cursor: text;

}

/************************** Grid 2 ***************************/

.services-grid2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: bottom;
    background-color: #F9FAFC;
    padding: 2% 8% 5% 8%;
}


.services-h2 {
    font-family: sans-serif;
    line-height: 64px;
    font-size: 64px;
    flex-wrap: wrap;
    width: 100%;
    color: #00334e;
    padding-bottom: 14px;
    cursor: text;

}

.services-textH {
    text-align: center;
    cursor: text;

}

.services-textP {
    text-align: center;
    font-size: 17px;
    padding-top: 7%;
    color: #8D8E8E;
    cursor: text;

}

.services-p {
    width: 100%;
    font-family: sans-serif;
    font-size: 16px;
    color: #777777;
    cursor: text;

}

.services-h2 {
    font-family: sans-serif;
    color: #00334e;
    padding-bottom: 14px;
    cursor: text;

}

.hr-line:after {
    content: " ";
    display: block;
    border-bottom: 1px solid #E5E5E5;
}

/****************** Grid 3   *********************/

.services-grid3 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: bottom;
    padding: 5% 8% 5% 8%;
    /*background-color: #f0f0f0;*/
}


.services-h2 {
    font-family: sans-serif;
    line-height: 64px;
    font-size: 64px;
    flex-wrap: wrap;
    width: 100%;
    cursor: text;
    padding-bottom: 14px;
}

/* ******************* Container 3 ******************/


.list-service {
    font-size: 120%;
    font-family: sans-serif;
    color: #777777;
    cursor: text;

}

ul {
    list-style-type: none;
}

li {
    margin: 10px 0;
}

/* ******************* Container 4 ******************/

.services-h2 {
    width: 100%;
    font-size: 48px;
    color: #00334e;
    cursor: text;

}

@media only screen and (max-width: 768px) {
    .services-h2 {
        font-size: 24px;
    }

    .list-service {
        font-size: 100%;
    }

    .services-grid3 {
        padding: 5% 1% 5% 1%;
    }


}

