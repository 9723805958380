
.about-h1 {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    color: #00334E;
}

.about-p {
    width: 100%;
    font-family: sans-serif;
    font-size: 16px;
    color: #777777;
    text-align: center;
}

.about-grid1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    padding: 5% 8% 2% 8%;
}

.width-p {
    width: 50%;
    margin: 0 auto;
}

@media only screen and (max-width: 768px) {
    .about-h1 {
        font-size: 22px;
    }

    .about-p {
        font-size: 16px;
        width: 100%;
    }

    .width-p {
        width: 100%;
    }


}


