@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Merriweather:wght@300&display=swap');

.grid-header {
    width: 60%;
    position: relative;
}

/*.angle{*/
/*    width: 50%;*/
/*    height: 100%;*/
/*    margin-left: -200px;*/
/*    transform: skew(-30deg);*/
/*    background: rgba(0,0,0,0.6);*/
/*}*/


.overlayText {
    position: absolute;
    top: 50%;
    left: 26%;
    width: 46%;
    z-index: 3;
    transform: translate(-50%, -50%);
    padding-left: 3%;
    font-size: 48px;
    line-height: 54px;
    font-family: Merriweather, serif;
    color: white;
    cursor: text;
}

.overlayText2 {
    font-size: 24px;
    line-height: 8px;
    width: 100%;
    top: 1%;
    left: 0;
}


.angle {
    display: inline-block;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.angle img {
    position: relative;
    z-index: 1;
    width: 100%;
}

.angle:after {
    position: absolute;
    content: '';
    display: inline-block;
    /*width: 100%;*/
    /*height: 100%;*/
    bottom: 1px;
    left: 0;
    z-index: 2;
    /*background: linear-gradient(33.5deg, rgba(255, 50, 54, 0.5) 0%, rgba(255, 26, 28, 0.5) 49.9%, rgba(255, 25, 27, 0) 50%, rgba(255, 0, 0, 0) 100%);*/
    background: rgba(0, 0, 0, 0.6);
    transform: skew(-30deg);
    margin-left: -200px;
    width: 65%;
    height: 100%;
}


.home-grid1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: bottom;
    background-color: #FFFFFF;
    padding: 5% 8% 5% 8%;
    /*background-image: url("../../images/cogs.png");*/
}

.home-box {
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 3%;
    box-shadow: 0 8px 10px -10px rgba(0, 0, 0, 0.5);
    background-image: url("../../images/cogs.png");
    background-repeat: no-repeat;

}


.home-h1 {
    font-family: sans-serif;
    line-height: 38px;
    font-size: 34px;
    flex-wrap: wrap;
    font-weight: bold;
    width: 88%;
    color: #00334e;
    padding-bottom: 14px;
    padding-left: 8px;
    cursor: text;

}

.home-p1 {
    width: 70%;
    font-size: 16px;
    font-weight: bold;
    padding-left: 8px;
    font-family: sans-serif;
    color: #777777;
    text-align: center;
    cursor: text;
    margin: auto;
}

.home-logoP {
    width: 100%;
    font-size: 26px;
    font-weight: bold;
    padding-left: 8px;
    font-family: sans-serif;
    text-align: center;
    cursor: text;

}

.main-image {
    height: 8%;
    width: 8%;
}

/****************** Second Container ******************/
.grid2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: bottom;
    padding: 5% 8% 5% 8%;
    background-color: #F9FAFC;

}


.home-h2 {
    font-family: sans-serif;
    line-height: 38px;
    font-size: 34px;
    flex-wrap: wrap;
    font-weight: bold;
    width: 88%;
    color: #00334e;
    padding-bottom: 14px;
    cursor: text;

}

.home-p2 {
    width: 95%;
    font-size: 16px;
    color: #777777;
    cursor: text;
    font-family: sans-serif;

}

/****************** Third Container ******************/

.grid3 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: bottom;
    background-color: #FFFFFF;
    padding: 5% 8% 5% 8%;
    /*background-image: url("../../images/cogs.png");*/

}

.home-h3 {
    font-family: sans-serif;
    line-height: 38px;
    font-size: 34px;
    font-weight: bold;
    flex-wrap: wrap;
    width: 86%;
    color: #00334e;
    padding-bottom: 14px;
}

.home-p3 {
    width: 94%;
    font-size: 16px;
    color: #777777;
    font-family: sans-serif;

}

/****************** Fourth Container ******************/


.container4 {
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding-top: 100px;*/
    background-color: #000e29;
    margin: 130px;
    padding-bottom: 50px;
    padding-top: 10px;
}

.white-p {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
}

@media only screen and (max-width: 768px) {
    .grid-header {
        display: none;
    }

    .home-p1 {
        width: 95%;
    }

    .home-h2 {
        font-size: 20px;
    }

    .home-h3 {
        font-size: 22px;
    }

}